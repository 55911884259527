import { Layout, SubHeader } from 'components/global'
import LegalBody from 'components/shared/legalBody'
import React from 'react'

const PrivacyPolicy = () => (
  <Layout $noAnimation>
    <SubHeader title="Privacy Policy" />
    <LegalBody>
      <p>
        At Pitch International, we take your privacy seriously. Pitch
        International LLP (Company No. OC309250) (PI) is committed to protecting
        the privacy of: our clients, users of our website and individuals who
        attend PI's sporting and corporate events or participate in the making
        of our on-screen productions and any other individuals we interact with
        from time to time.
      </p>
      <p>
        This Privacy Policy states our policy and practices regarding the
        collection and use of personal data, including helping ensure that we
        provide a safe and secure user experience on our websites. We will
        ensure that the information you submit to us, or which we collect, via
        various channels (including our websites, through written correspondence
        (including e-mail), or through any of our offline locations), is only
        used for the purposes set out in this Privacy Policy.
      </p>
      <p>
        Through this Privacy Policy we aim to inform you about the types of
        personal data we collect, the purposes for which we use the data and the
        ways in which the personal data is handled. We also aim to satisfy our
        obligation of transparency under the EU General Data Protection
        Regulation 2016/679 ("<em>GDPR</em> ") and the national laws
        implementing GDPR.
      </p>
      <p>
        For the purpose of this Privacy Policy the controller of personal data
        is PI and our contact details are set out in the &lsquo;Contact
        Us&rsquo; section at the end of this Privacy Policy. When we refer to
        PI, "we", "us" or "our" we are referring to Pitch International LLP and
        the other companies in our group. Currently, the companies in our group
        of companies are located in the United Kingdom and includes Pitch
        International Representation Limited (Company No. 10779876) and Star
        Sixes Limited (Company No. 10306590) (our Group).
      </p>
      <section>
        <h5>Right to Object: </h5>
        <p>Please note that you have a legal right to object at any time to:</p>
        <ul>
          <li>
            use of your personal information for direct marketing purposes; or
          </li>
          <li>
            processing of your personal information which is based on our
            legitimate interests unless there are compelling legitimate grounds
            for our continued processing.
          </li>
        </ul>
      </section>
      <h5>The Information we collect</h5>
      <p>
        In general, you can visit the PI website without identifying yourself or
        revealing any personal information. However, you may choose to provide
        us with personal data (e.g. via our Contact Us page, or when you opt-in
        to receive our marketing communications). Personal data is information
        that can be used to identify you, such as your name, title, email
        address, telephone number, industry and company details or similar
        information.&nbsp;
      </p>
      <p>
        When you register for or attend our sporting or corporate events, PI
        will ask you to provide: basic contact information and information on
        your participation in the events. PI may also ask you to provide billing
        information or a photograph if you require accreditation to enter
        specific venues.
      </p>
      <p>
        When you participate in the making of our on-screen productions, PI may
        ask you to provide: basic contact information, including emergency
        contact details and information on your participation in the production.
      </p>
      <p>
        In addition to the above, if we are booking any travel and/or
        accommodation for you, PI may ask you to provide identification
        documentation (usually your passport) and airmiles or hotel loyalty
        scheme details.
      </p>
      <h5>How we use your Personal Data&nbsp;</h5>
      <p>
        You can be assured that any personal data you provide voluntarily will
        only be used in connection with your business relationship with PI. We
        will hold, use and disclose your personal data for our legitimate
        business purposes including:
      </p>
      <ul>
        <li>to provide the services we perform for you;</li>
        <li>to better understand your needs and to provide better service;</li>
        <li>to answer your questions and deal with your requests;</li>
        <li>to plan and host sporting and corporate events;</li>
        <li>
          from time to time, we may also, with your consent, use your
          information to contact you for market research or to provide you with
          marketing information we think would be of particular interest. At a
          minimum, we will always give you the choice to opt-out of receiving
          such direct marketing or market research contact. You can do this by
          following the unsubscribe links on any marketing message sent to you
          or by contacting us at any time (via the contact details set out in
          the &lsquo;Contact Us&rsquo; section at the end of this Privacy
          Policy).
        </li>
      </ul>
      <p>
        PI uses credit card numbers only for payment processing and are not
        retained for other purposes. PI uses passport information only for
        booking travel and/or accommodation and is not retained for other
        purposes.
      </p>
      <p>
        PI may receive information about you from other third parties and
        combine this information with information we already have about you.
        This helps us to better understand your needs and to provide a better
        service.
      </p>
      <p>
        We will not use or share the personal data provided to us in ways
        unrelated to the uses described in this statement. More information
        about how we may share your information can be found at section 5.
      </p>
      <p>
        <strong>
          The Legal Basis for Processing your Personal Information
        </strong>
      </p>
      <p />
      <ul>
        <li>
          Under GDPR, the main grounds that we rely upon in order to process
          your personal data are the following:
        </li>
        <li>
          (a){' '}
          <strong>
            Necessary for entering into, or performing, a contract
          </strong>{' '}
          &ndash; in order to perform obligations that we undertake in providing
          a service to you, or in order to take steps at your request to enter
          into a contract with us, it will be necessary for us to process your
          personal data;
        </li>
        <li>
          (b) <strong>Necessary for compliance with a legal obligation</strong>{' '}
          &ndash; we are subject to certain legal requirements which may require
          us to process your personal data. We may also be obliged by law to
          disclose your personal data to a regulatory body or law enforcement
          agency;
        </li>
        <li>
          (c){' '}
          <strong>Necessary for the purposes of legitimate interests</strong> -
          either we, or a third party, will need to process your personal data
          for the purposes of our (or a third party's) legitimate interests,
          provided we have established that those interests are not overridden
          by your rights and freedoms, including your right to have your
          personal data protected. Our legitimate interests include responding
          to requests and enquiries from you or a third party, optimising our
          website, our market research, informing you about our products and
          services and ensuring that our operations are conducted in an
          appropriate and efficient manner; and
        </li>
        <li>
          (d) <strong>Consent</strong> &ndash; in some circumstances, we may ask
          for your consent to process your personal data in a particular way.
        </li>
      </ul>
      <p />
      <p>
        <strong>How we share your Personal Information </strong>
      </p>
      <p />
      <p>
        We may disclose your personal data with other parties. Details of those
        parties are set out below along with the reasons for sharing it.
      </p>
      <ol>
        <li>
          <strong>Other Companies within our Group within the EEA</strong>
          <p>
            When you disclose your personal data to us we may share it with
            other companies in our Group of companies in the European Economic
            Area ("EEA"). We apply equal rigour to the security of data held and
            processed across the Group and the whole of our network.
          </p>
          <p>
            We will share your personal information as above for any or all of
            the following purposes:
          </p>
          <ul>
            <li>
              To work with other PI affiliates within our Group of companies
            </li>
            <li>Customer support</li>
            <li>Marketing</li>
            <li>Technical operations</li>
            <li>Account management purposes</li>
          </ul>
        </li>
        <li>
          <strong>Third Parties</strong>
          <p>
            We will share your personal data with trusted third parties where we
            have retained them to provide services that you or our clients have
            requested, such as:
          </p>
          <ul>
            <li>Enhancing business contact information</li>
            <li>Managing our IT operations</li>
            <li>Facilitating joint-marketing efforts</li>
            <li>Optimising film production content</li>
            <li>Organising any corporate or sporting event you attend</li>
            <li>Arranging your travel, accommodation and/or hospitality</li>
            <li>Arranging accreditation at certain venues</li>
          </ul>
          <p>Such third parties as referenced in paragraph ii. may include:</p>
          <ul>
            <li>
              Providers of IT infrastructure, digital support and data centre
              management to help us to protect the security of your information,
              ensure our online services remain fit for purpose and perform
              systems testing;
            </li>
            <li>Telephone services providers for communication;</li>
            <li>External auditors to independently assess us;</li>
            <li>
              Offsite data storage facilities, for efficient data management and
              security; and
            </li>
            <li>
              Travel agencies, hotels, venues and sports organisations (or their
              third-party suppliers) for the purpose of arranging your travel,
              accommodation and attendance at certain events.
            </li>
          </ul>
          <p>
            We may pass aggregated information to third parties about how our
            users use our services but this will not include information which
            could be used by them to identify you. For further information see
            Section 8 below.
          </p>
          <p>
            We require minimum standards of confidentiality and data protection
            from such third parties. To the extent that any personal data is
            provided to third parties outside the EEA, or who will access the
            information from outside the EEA, we will ensure that approved
            safeguards are in place, including entering into the Standard
            Contractual Clauses as approved by the European Commission.
          </p>
          <p>
            We will not share your sensitive personal information with any third
            parties without your consent.
          </p>
          <p />
          <ul>
            <li>
              <strong>Regulatory and Law Enforcement Agencies </strong>
            </li>
          </ul>
          <p />
          <p>
            We may disclose your personal data if required by applicable law,
            regulation or as part of a legal process. If we receive a request
            from a regulatory body or law enforcement agency, and if permitted
            under GDPR and other laws, we may disclose certain personal data to
            such bodies or agencies. This may include fraud prevention agencies
            to help us to prevent and detect financial crime and fraud.
          </p>
        </li>
        <li>
          <strong>New business owners </strong>
          <p>
            If we or our business merges with or is acquired by, or acquires
            another business or company, we may share your personal data with
            the (new) owners of the business or company and their advisors.
          </p>
        </li>
      </ol>
      <p>
        <strong>How long we will hold your information </strong>
      </p>
      <p>
        We will retain your personal data for the time necessary to provide the
        services we perform for you or stated by the purposes outlined in this
        Privacy Policy, and you can always request that we stop processing or
        delete your personal data. The next section sets out more information on
        your rights.
      </p>
      <p>
        <strong>
          Your rights in relation to the information we hold about you{' '}
        </strong>
      </p>
      <p>
        You have certain rights in relation to the personal information we hold
        about you. Details of these rights and how to exercise them are set out
        below. We will require evidence of your identity before we are able to
        act on your request. If you wish to exercise any of these rights, please
        contact us using the contact details below.
      </p>
      <p>
        <strong>Right of Access</strong>
      </p>
      <p>
        You have the right at any time to ask us for a copy of the personal data
        that we hold about you. Where we have good reason, and if the GDPR
        permits, we can refuse your request for a copy of your personal data, or
        certain elements of the request.
      </p>
      <p>
        <strong>Right of Correction or Completion</strong>
      </p>
      <p>
        If personal data we hold about you is not accurate, out of date or
        incomplete, you have a right to have the data rectified, updated or
        completed.&nbsp;
      </p>
      <p>
        <strong>Right of Erasure</strong>
      </p>
      <p>
        In certain circumstances, you have the right to request that personal
        data we hold about you is erased e.g. if the information is no longer
        necessary for the purposes for which it was collected or processed or
        our processing of the information is based on your consent and there are
        no other legal grounds on which we may process the information.
      </p>
      <p>
        <strong>&nbsp;Right to object to or restrict processing</strong>
      </p>
      <p>
        In certain circumstances, you have the right to object to our processing
        of your personal data. For example, if we are processing your
        information on the basis of our legitimate interests and there are no
        compelling legitimate grounds for our processing which override your
        rights and interests. You also have the right to object to use of your
        personal data for direct marketing purposes.
      </p>
      <p>
        You may also have the right to restrict our use of your personal data,
        such as in circumstances where you have challenged the accuracy of the
        information and during the period where we are verifying its accuracy.
      </p>
      <p>
        <strong>Right of Data Portability</strong>
      </p>
      <p>
        In certain instances, you have a right to receive any personal data that
        we hold about you in a structured, commonly used and machine-readable
        format. You can ask us to transmit that information to you or directly
        to a third party organisation.
      </p>
      <p>The above right exists only in respect of personal data that:</p>
      <ul>
        <li>you have provided to us previously; and</li>
        <li>is processed by us using automated means.</li>
      </ul>
      <p>
        While we are happy for such requests to be made, we are not able to
        guarantee technical compatibility with a third party organisation's
        systems. We are also unable to comply with requests that relate to
        personal information of others without their consent.&nbsp;
      </p>
      <p>
        <strong>Right of Data Portability</strong>
      </p>
      <p>
        In certain instances, you have a right to receive any personal data that
        we hold about you in a structured, commonly used and machine-readable
        format. You can ask us to transmit that information to you or directly
        to a third party organisation.
      </p>
      <p>The above right exists only in respect of personal data that:</p>
      <ul>
        <li>you have provided to us previously; and</li>
        <li>is processed by us using automated means.</li>
      </ul>
      <p>
        While we are happy for such requests to be made, we are not able to
        guarantee technical compatibility with a third party organisation's
        systems. We are also unable to comply with requests that relate to
        personal information of others without their consent.&nbsp;
      </p>
      <p>
        <strong>Consent</strong>
      </p>
      <p>
        To the extent that we are processing your personal data based on your
        consent, you have the right to withdraw your consent at any time.
      </p>
      <p>
        You can exercise any of the above rights by contacting us at
        privacy@pitchinternational.com.
      </p>
      <p>
        Most of the above rights are subject to limitations and exceptions. We
        will provide reasons if we are unable to comply with any request for the
        exercise of your rights.
      </p>
      <p />
      <ol>
        <li>
          <strong>Aggregate Information about PI online visitors </strong>
        </li>
      </ol>
      <p />
      <p>
        We gather information and statistics collectively about all visitors to
        our website, for example:
      </p>
      <ul>
        <li>which area users access most frequently</li>
        <li>the type of operating system and browser software</li>
        <li>the geographical location of browsers</li>
        <li>broad demographic information.</li>
      </ul>
      <p>
        We only use such data in the aggregate form and our analytical services
        do not record any personal data. The information and statistics we
        collect helps us determine what is most beneficial for our users and how
        we can continually improve our online services to create a better
        overall experience for our users. Such data may be shared with third
        parties, such as advertisers, however the underlying data is not shared
        with anybody else.
      </p>
      <p />
      <ol start="2">
        <li>
          <strong>Use of Cookies </strong>
        </li>
      </ol>
      <p />
      <p>
        We use &ldquo;cookies&rdquo; and other similar technologies to help
        provide you with a better, more personalised user experience. A cookie
        is a string of information that a website stores on a visitor&rsquo;s
        device or computer, and that the visitor&rsquo;s browser provides to the
        website each time the visitor returns. We use these technologies to help
        us understand and improve how visitors use our website. The two types of
        cookies we use are known as "session" cookies and "persistent" cookies.
        These may be cookies which we use and/or a third party uses.
      </p>
      <p>
        <br />
        <strong>Session cookie: </strong>A session cookie would include, for
        example, session ID, user browser/platform and support service contact
        time. A session cookie is used each time you visit our website to give
        you a session ID. We use this type of cookie to link your actions on our
        website and each session cookie will only last for a browser session, at
        the end of which it will expire. Each browser session will start when
        you open your browser window and finish when you close your browser
        window. After your visit all session cookies are deleted. Each time you
        visit our website you will have a different session ID.
      </p>
      <p>
        <br />
        <strong>Persistent cookie:</strong> A persistent cookie allows the
        preferences or actions of the user across a website (or across different
        websites) to be remembered (such as for example your language
        preferences). It has a longer lifetime than a session cookie and lasts
        for the period of time specified in the cookie (this will vary from
        cookie to cookie). This type of cookie will not be deleted when you
        close your browser window and will be stored on your device. It will be
        activated every time you visit the website or application which created
        that particular persistent cookie.
      </p>
      <p>
        <br />
        The cookies we use fall into the following categories:
      </p>
      <p>
        <br />
        <strong>Strictly necessary cookie:</strong> These cookies are essential
        to our online services in order to enable you to move around our website
        and applications and to use features, such as remembering information
        you have entered on forms when you move to other pages within a browser
        session. Without strictly necessary cookies, certain services which you
        have asked for will not be made possible and our online services will
        not perform as smoothly as we think you would expect. Accepting strictly
        necessary cookies is a condition of using our online services. If you
        reject these cookies, then we cannot predict how our service will
        perform when you visit it nor guarantee your security.
      </p>
      <p>
        &nbsp;
        <br />
        <strong>Performance cookie: </strong>Performance cookies collect
        information about your use of our website and enable us to improve the
        way they work. For example, performance cookies will show us which are
        the most frequently visited pages on our website, help us record any
        difficulties you have and show us whether our advertising is effective
        or not (but note that performance cookies do not collect information to
        display targeted advertising on other websites). Performance cookies do
        not collect information that identifies you personally and all
        information that is collected by these cookies is aggregated and
        anonymous. In some cases, we may use third parties to manage our
        performance cookies, but we do not allow any third party to use these
        cookies other than for the types of purpose listed in this paragraph.
        For example, they do not remember information about you for the purposes
        of targeting adverts at you on other websites.
      </p>
      <p>
        <br />
        Accepting performance cookies is a condition of using our online
        services. If you reject these cookies, then we cannot predict how our
        service will perform when you visit it nor guarantee your security.
      </p>
      <p>
        <br />
        <strong>Functionality cookie: </strong>Functionality cookies allow us to
        remember the choices you make on our website and to provide enhanced and
        more personal features. We also use functionality cookies to remember
        changes you have made, such as customising a certain webpage,
        remembering if we have asked you to participate in a promotion and for
        other services you request such as watching a video or commenting on a
        blog. All of these features help us to improve your visit to our online
        services. Our functionality cookies will not track your activity on
        other websites. In some cases, we may use third parties to manage our
        functionality cookies, but we do not allow any third party to use these
        cookies other than for the types of purposes listed in this paragraph.
        For example, they do not remember information about you for the purposes
        of targeting adverts at you on other websites.
      </p>
      <p>
        <br />
        You can control our use of functionality cookies. If you prevent the use
        of functionality cookies then this may mean that we cannot offer some
        services and may interrupt your enjoyment of it (for example, we may not
        be able to record your request not to offer you a particular type of
        service). This may also mean we are limited in the scope of support we
        can offer you.
      </p>
      <p>
        <br />
        <strong>Third party cookies</strong>: Third party advertisers and other
        organisations (including social media services) may use their own
        cookies or other technologies to collect information about the content
        of our online services and/or the advertisements you have clicked on.
        Third party advertisers may also use this information to measure the
        effectiveness of their advertisements. We would not have access or
        control over the cookies placed by third parties and use of their
        cookies is subject to their own privacy policies. We recommend that you
        review the privacy policies of any third parties that have a presence on
        our website. <br />
        <br />
        Cookies placed by third parties may serve different purposes. They may
        be strictly necessary, performance, functionality, targeting or
        advertising cookies. To disable or reject third party cookies you must
        go to the relevant third party&rsquo;s website.
      </p>
      <p>
        <strong>Managing cookies</strong>
      </p>
      <p />
      <p>
        By law the default setting on your web browser is to prohibit cookies,
        but you may have changed this during the installation process. If you
        are unsure of your cookie settings, we recommend visiting the website
        www.aboutcookies.org. This site gives detailed guidance on how to
        control and delete cookies. Most browsers will allow you to opt-in to
        different categories of cookie. In particular, the Help feature on most
        browsers will tell you how to prevent your browser from accepting new
        cookies, how to have the browser notify you when you receive a new
        cookie and how to disable or delete cookies altogether. Additionally,
        you can disable or delete similar data used by browser add-ons, such as
        Flash cookies, by changing the add-on's settings or visiting the website
        of its manufacturer.
      </p>
      <ol>
        <li>
          <strong>Complaints </strong>
        </li>
      </ol>
      <p />
      <p>
        If you are unhappy about our use of your personal data, you can contact
        us using the details in the Contact section below. You are also entitled
        to lodge a complaint with the UK Information Commissioner's Office using
        any of the below contact methods:
      </p>
      <p>Telephone: 0303 123 11113</p>
      <p>
        <br />
        Website:{' '}
        <a href="https://ico.org.uk/concerns/">https://ico.org.uk/concerns/</a>
      </p>
      <p>
        <br />
        Post: Information Commissioner's Office
        <br /> Wycliffe House
        <br /> Water Lane
        <br /> Wilmslow
        <br /> Cheshire
        <br /> SK9 5AF
      </p>
      <p>
        If you live or work outside the UK or you have a complaint concerning
        our activities outside the UK, you may prefer to lodge a complaint with
        your local supervisory authority.
      </p>
      <p />
      <ol start="2">
        <li>
          <strong>Security</strong>
        </li>
      </ol>
      <p />
      <p>
        We have put in place measures to ensure the security of the information
        we collect and store about you and will use our reasonable endeavours to
        protect your personal data from unauthorised disclosure and/or access
        including through the use of network and database security measures, but
        these cannot guarantee the security of any data which is collected and
        stored. We use encryption when collecting or transferring sensitive
        data, such as credit card information.
      </p>
      <ol start="3">
        <li>
          <strong>Other websites </strong>
        </li>
      </ol>
      <p />
      <p>
        Please note that clicking on links on our websites may result in your
        browser accessing a third-party website, where data privacy practices
        are different to that of PI. We are not responsible for, and have no
        control over, information that is submitted to or collected by these
        third parties and you should consult their privacy policies.
      </p>
      <p />
      <ol start="4">
        <li>
          <strong>Internet based transfers </strong>
        </li>
      </ol>
      <p />
      <p>
        Given that the internet is a global environment, using the internet to
        collect and process personal data necessarily involves the transmission
        of data on an international basis. Unfortunately, the transmission of
        information via the internet is not completely secure. Although we will
        do our best to protect your personal data, we cannot guarantee the
        security of your data transmitted to our site via third party networks;
        any transmission is at your own risk. Once we have received your
        personal data, we will use strict procedures and security features to
        try to prevent unauthorised access.
      </p>
      <p />
      <ol start="5">
        <li>
          <strong>Changes to our Privacy Policy </strong>
        </li>
      </ol>
      <p />
      <p>
        Our Privacy Policy is subject to change from time to time and, if we
        update it, we will post these changes on this page so that you will be
        aware of how we use your personal data.
      </p>
      <ol start="6">
        <li>
          <strong>Contact Us</strong>
        </li>
      </ol>
      <p>
        If you have any enquires or if you would like to contact us about our
        processing of your personal data, including to exercise your rights as
        outlined above, please contact us centrally by any of the methods below.
        When you contact us, we will ask you to verify your identity.
      </p>
      <p>Contact: Legal Team</p>
      <p>Email: privacy@pitchinternational.com</p>
      <p>Telephone: +44 (0)20 8789 6070</p>
      <p>Post: At the address below </p>
      <p>
        Our registered office is at: Pitch International LLP, 17 Brewhouse Lane,
        Putney&nbsp;Wharf, London SW15 2JX
      </p>
    </LegalBody>
  </Layout>
)

export default PrivacyPolicy
